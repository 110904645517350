import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import DashboardLayout from 'src/layouts/dashboard';
import Protected from 'src/utils/checkpermission';


export const IndexPage = lazy(() => import('src/pages/app'));
export const EmploeesPage = lazy(() => import('src/pages/users/main'));
export const EmploeesDataPage = lazy(() => import('src/pages/users/user'));
export const AddUserPage = lazy(() => import('src/pages/users/adduser'));

export const Mainstudents = lazy(() => import('src/pages/students/main'))
export const AddStudents = lazy(() => import('src/pages/students/addStudent'))
export const ReportStudents = lazy(() => import('src/pages/evaluation/reports-page'))
export const Students = lazy(() => import('src/pages/students/students'))

// Speacial Edu
export const StudentSE = lazy(() => import('src/pages/se/studentSe'))
export const ObjectLibrarySE = lazy(() => import('src/pages/se/categorySe'))
export const PEvalStudents = lazy(() => import('src/pages/se/preEvaluation'))
export const AddPEvalStudents = lazy(() => import('src/pages/se/addPreEval'))
export const StudentPlan = lazy(() => import('src/pages/se/plan'))
export const AddItemToPlan = lazy(() => import('src/pages/se/AddItemToPlan'))
export const AddResponseToPlan = lazy(() => import('src/pages/se/AddResponseToPlan'))
export const SeDepart = lazy(() => import('src/pages/departments/seDepart'))



export const Permission = lazy(() => import('src/pages/permissions/permissions'))
export const AddPermission = lazy(() => import('src/pages/permissions/addPermission'))

export const MainDeparts = lazy(() => import('src/pages/departments/main'))

// SpeachDepart
export const SpeachDepart = lazy(() => import('src/pages/departments/speachDepart'))
export const StudentSpeach = lazy(() => import('src/pages/speach/studentSpeach'))
export const PEvalStudentsSpeach = lazy(() => import('src/pages/speach/preEvaluation'))
export const StudentPlanSpeach = lazy(() => import('src/pages/speach/plan'))
export const AddItemToPlanSpeach = lazy(() => import('src/pages/speach/AddItemToPlan'))
export const AddResponseToPlanSpeach = lazy(() => import('src/pages/speach/AddResponseToPlan'))


export const LoginPage = lazy(() => import('src/pages/login'));
export const Page404 = lazy(() => import('src/pages/page-not-found'));

// ----------------------------------------------------------------------

export default function Router() {
  const userAuth = secureLocalStorage.getItem('object')
  // const url = new URL(window.location.href)

  // function equalUrls(url1, url2) {
  //   return (
  //     new URL(url1, "http://localhost:3000/").pathname ===
  //     new URL(url2, "http://localhost:3000/").pathname
  //   );
  // }


  let authRouter = []

  if (userAuth) {
    if (userAuth.token) {

      authRouter = [
        {
          element: (
            <DashboardLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          ),
          children:
            [
              { path: '/', element: <IndexPage /> },
              {
                path: 'employees',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse' || userAuth.user.role === 'centermanager'
                    ? <EmploeesDataPage />
                    : <IndexPage />
              },
              {
                path: 'employees/add',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse' || userAuth.user.role === 'centermanager'
                    ? <AddUserPage />
                    : <IndexPage />
              },
              {
                path: 'employeedit/:user_id',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse' || userAuth.user.role === 'centermanager'
                    ? <AddUserPage />
                    : <IndexPage />
              },

              {
                path: 'permissions', element: userAuth.user.role === 'sysadmin' ? <Permission /> : <IndexPage />
              },
              { path: 'permissions/:permission_id', element: userAuth.user.role === 'sysadmin' ? <AddPermission /> : <IndexPage /> },

              {
                path: 'students/studentsadd',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse'
                    ? <AddStudents />
                    : <IndexPage />
              },
              {
                path: 'students',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse'
                    ? <Students />
                    : <IndexPage />
              },
              {
                path: 'students/edit/:student_id',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse'
                    ? <AddStudents />
                    : <IndexPage />
              },
              {
                path: 'students/reports/:student_id',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse' || userAuth.user.role === 'teacherse'
                    ? <ReportStudents />
                    : <IndexPage />
              },

              {
                path: 'departs',
                element:
                  userAuth.user.role === 'sysadmin'
                    ? <MainDeparts />
                    : <IndexPage />
              },
              {
                path: 'departs/se',
                element:
                  userAuth.user.role === 'sysadmin'
                    ? <SeDepart />
                    : <IndexPage />
              },
              {
                path: 'se/students',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse' || userAuth.user.role === 'teacherse'
                    ? <StudentSE />
                    : <IndexPage />
              },
              {
                path: 'se/objectlibrary',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse'
                    ? <ObjectLibrarySE />
                    : <IndexPage />
              },
              {
                path: 'se/pevaluation',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse' || userAuth.user.role === 'teacherse'
                    ? <PEvalStudents />
                    : <IndexPage />
              },
              {
                path: 'se/addpevaluation/:student_id',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'teacherse'
                    ? <AddPEvalStudents />
                    : <IndexPage />
              },
              {
                path: 'se/studentsplan/:student_id',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse' || userAuth.user.role === 'teacherse'
                    ? <StudentPlan />
                    : <IndexPage />
              },
              {
                path: 'se/additemtoplan',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'superse' || userAuth.user.role === 'teacherse'
                    ? <AddItemToPlan />
                    : <IndexPage />
              },
              {
                path: 'se/addresponsetoplan/:plan_id',
                element:
                  userAuth.user.role === 'sysadmin' || userAuth.user.role === 'teacherse'
                    ? <AddResponseToPlan />
                    : <IndexPage />
              },
              {
                path: 'departs/speach',
                element:
                  userAuth.user.role === 'sysadmin'
                    ? <SpeachDepart />
                    : <IndexPage />
              },
              {
                path: 'speach/students',
                element:
                  userAuth.user.role === 'sysadmin'
                    ? <StudentSpeach />
                    : <IndexPage />
              },
              {
                path: 'speach/pevaluation/:student_id',
                element:
                  userAuth.user.role === 'sysadmin'
                    ? <PEvalStudentsSpeach />
                    : <IndexPage />
              },
              {
                path: 'speach/studentsplan/:student_id',
                element:
                  userAuth.user.role === 'sysadmin'
                    ? <StudentPlanSpeach />
                    : <IndexPage />
              },
              {
                path: 'speach/additemtoplan/:plan_id',
                element:
                  userAuth.user.role === 'sysadmin'
                    ? <AddItemToPlanSpeach />
                    : <IndexPage />
              },
              {
                path: 'speach/addresponsetoplan/:plan_id',
                element:
                  userAuth.user.role === 'sysadmin'
                    ? <AddResponseToPlanSpeach />
                    : <IndexPage />
              },
            ]
          ,
        },
        {
          path: '/',
          children: [
            { path: '/', element: <Navigate to="/" /> },
            { path: 'login', element: <Navigate to="/" /> },
            { path: '404', element: <Page404 /> },
            { path: '*', element: <Navigate to="/404" replace /> },
          ],
        },
      ]
    }
  } else {
    authRouter = [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: '404',
        element: <Page404 />,
      },
      {
        path: '*',
        element: <Navigate to="/login" replace />,
      },
      {
        path: '/',
        children: [
          { path: '/', element: <Navigate to="/login" /> },
          // { path: '*', element: <Navigate to="/login" /> },
        ],
      },
      // {
      //   path: '*',
      //   element: <Navigate to="/login" replace />,
      // },
    ]
  }

  const routes = useRoutes(authRouter)

  return routes;
}
